<template>
  <v-card
    class="mx-auto"
    :to="
      !showBackArrow
        ? {
            name: 'show-' + [namespace],
            params: { id: item.id }
          }
        : ''
    "
  >
    <v-card-title class="d-flex">
      <span
        class="font-weight-light text-capitalize"
        :class="showBackArrow ? 'break-words' : 'text-truncate '"
        v-if="item.title"
      >
        <v-btn icon @click="goBack()" v-if="showBackArrow">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-icon left v-if="icon">{{ icon }}</v-icon>
        {{ item.title }}</span
      >
    </v-card-title>
    <v-img
      class="white--text align-end"
      contain
      :height="imageHeight"
      :src="item.pictureUrl"
      @error="item.pictureUrl = require('@/assets/picture-loading-failed-1.png')"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <v-card-subtitle>{{ formatDateAndTime(item.date) }}</v-card-subtitle>

    <v-card-text class="text--primary">
      <div class="row">
        <div class="col-12 break-words" v-html="limitSize(item.text, limitText)"></div>
      </div>
    </v-card-text>
    <v-divider v-if="showActions && isAdmin()"> </v-divider>
    <v-card-actions v-if="showActions && isAdmin()">
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :to="{
              name: 'show-' + [namespace],
              params: { id: item.id }
            }"
            class="pa-1"
            icon
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tooltip.show") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :to="{
              name: 'edit-' + [namespace],
              params: { id: item.id }
            }"
            class="pa-1"
            icon
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tooltip.edit") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="error--text pa-1"
            icon
            v-on:click.prevent="confirmDeleteDialog = true"
          >
            <v-icon color="error">mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tooltip.delete") }}</span>
      </v-tooltip>
    </v-card-actions>

    <delete-dialog
      v-if="confirmDeleteDialog"
      :dialog.sync="confirmDeleteDialog"
      @dialog-cancel="confirmDeleteDialog = false"
      @dialog-update="deleteItem"
    ></delete-dialog>
  </v-card>
</template>

<script>
export default {
  components: {
    DeleteDialog: () => import("@/components/core/dialogs/DeleteDialog")
  },
  data() {
    return {
      confirmDeleteDialog: false
    };
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    imageHeight: {
      type: String,
      required: false,
      default: "200px"
    },
    limitText: {
      type: String,
      required: false,
      default: "40"
    },
    showActions: {
      type: Boolean,
      required: false,
      default: false
    },
    namespace: {
      type: String,
      required: false,
      default: ""
    },
    showBackArrow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    deleteItem() {
      this.$emit("delete-item", this.item.id);
      this.confirmDeleteDialog = false;
    }
  }
};
</script>

<style scoped>
.break-all-words >>> p {
  margin-bottom: 0 !important;
}
.break-all-words {
  word-break: break-all;
}
.break-words {
  word-break: break-word;
}
</style>
